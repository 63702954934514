import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Structure, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ekspert naprawy sprzętu
			</title>
			<meta name={"description"} content={"Twój niezawodny partner w naprawie sprzętu"} />
			<meta property={"og:title"} content={"Ekspert naprawy sprzętu"} />
			<meta property={"og:description"} content={"Twój niezawodny partner w naprawie sprzętu"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/mobile-phone-repair.jpg?v=2024-06-13T09:31:00.740Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/GettyImages-1305200685-5c776bc194f04a0597bd70bddc736670.jpg?v=2024-06-13T09:31:00.759Z) 80%/cover scroll"
			padding="120px 0 120px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
			min-height="70vh"
		>
			<Override slot="SectionContent" height="auto" justify-content="center" />
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0">
						Twój niezawodny partner w naprawie sprzętu
					</Text>
				</StackItem>
			</Stack>
			<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
				STARAMY SIĘ ZAPEWNIĆ PAŃSTWU NAJWYŻSZĄ JAKOŚĆ OBSŁUGI, SZYBKOŚĆ I NIEZAWODNOŚĆ.
			</Text>
		</Section>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" border-color="--color-secondary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline5"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					„Ekspert Naprawa Sprzętu” to zespół wysoko wykwalifikowanych specjalistów z wieloletnim doświadczeniem w dziedzinie naprawy sprzętu.
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Pracujemy z różnymi sprzętami AGD, m.in. komputerami, smartfonami, sprzętem AGD i RTV. Nasi rzemieślnicy stale doskonalą swoją wiedzę i umiejętności, aby nadążać za najnowszymi innowacjami i technologiami.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09:31:00.735Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					height="100%"
					object-position="bottom"
					box-shadow="--xl"
					border-radius="30px"
					srcSet="https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/professional-cell-phone-repair.jpg?v=2024-06-13T09%3A31%3A00.735Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="9fr 3fr" md-grid-template-columns="repeat(6, 2fr)" sm-grid-template-columns="12fr">
				<Override
					slot="Cell 0th"
					grid-column="1 / auto"
					grid-row="auto / span 2"
					md-grid-column="1 / span 6"
					md-grid-row="span"
					sm-grid-column="auto"
					sm-grid-row="span"
				/>
				<Override slot="Cell 1st" md-grid-column="1 / span 3" sm-grid-column="auto" align-self="end" />
				<Override slot="Cell 2nd" md-grid-column="4 / span 3" sm-grid-column="auto" align-self="end" />
				<Override slot="cell-0">
					<Text
						margin="0px 0px 24px 0px"
						color="#ffffff"
						font="--headline2"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-transform="uppercase"
					>
						NASZE USŁUGI
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--base"
						color="--light"
						text-align="left"
						lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="0px 0px 32px 0px"
					>
						Oferujemy szeroką gamę usług związanych z naprawą i konserwacją sprzętu
					</Text>
					<Image
						src="https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09:31:00.745Z"
						display="block"
						max-height="100%"
						max-width="80%"
						box-shadow="--xl"
						border-radius="30px"
						margin="2rem 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66696d2e3f24780021f7ccff/images/AdobeStock_246948710.jpeg?v=2024-06-13T09%3A31%3A00.745Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Override>
				<Override slot="cell-1">
					<Text
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Instalowanie oprogramowania i aktualizacja systemów
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Konserwacja i konfiguracja urządzeń sieciowych
					</Text>
				</Override>
				{"        "}{"        "}{"        "}{"    "}
			</Override>
		</Structure>
		<Structure cells-number-total="3" cells-number-group="3">
			<Override slot="Content" grid-template-columns="repeat(3, 4fr)" sm-grid-template-columns="12fr">
				<Override slot="cell-0">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Naprawa komputerów i laptopów
					</Text>
				</Override>
				<Override slot="cell-1">
					<Text
						sm-margin="24px 0px 16px 0px"
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
					>
						Naprawa smartfonów i tabletów
					</Text>
				</Override>
				<Override slot="cell-2">
					<Text
						margin="32px 0px 16px 0px"
						font="--headline4"
						color="--green"
						lg-font="normal 600 20px/1.5 &quot;Varta&quot;, sans-serif"
						md-margin="12px 0px 16px 0px"
						sm-margin="24px 0px 16px 0px"
					>
						Naprawa sprzętu AGD
					</Text>
				</Override>
			</Override>
		</Structure>
		<Section padding="36px 0 60px 0" quarkly-title="HeroBlock" md-padding="16px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				width="100%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
			>
				<Text
					font="--headline2"
					color="--light"
					margin="0px 0px 0px 0px"
					lg-font="normal 700 50px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 700 40px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-margin="0px 0px 24px 0px"
					text-transform="uppercase"
				>
					DLACZEGO NAS WYBRALI
				</Text>
				<Text
					text-align="left"
					lg-font="normal 600 16px/1.5 &quot;Varta&quot;, sans-serif"
					lg-margin="80px 0px 0px 0px"
					md-margin="0px 0px 0px 0px"
					margin="40px 0px 0px 0px"
					color="--light"
					font="--lead"
				>
					Profesjonalizm i doświadczenie: Posiadamy wieloletnie doświadczenie i specjalizujemy się w naprawie sprzętu o dowolnej złożoności.{"\n"}
					<br />
					<br />
					{"\n"}Szybkość obsługi: Cenimy Twój czas i zapewniamy szybkie i wysokiej jakości naprawy.{"\n"}
					<br />
					<br />
					{"\n"}Gwarancja jakości: Udzielamy gwarancji na wszystkie rodzaje prac i używamy wyłącznie części zamiennych wysokiej jakości.{"\n"}
					<br />
					<br />
					{"\n"}Indywidualne podejście: Każdy Klient jest dla nas wyjątkowy i zawsze staramy się znaleźć najlepsze rozwiązanie dla każdego przypadku.{"\n"}
					<br />
					<br />
					{"\n"}Przystępne ceny: Oferujemy konkurencyjne ceny bez ukrytych opłat.
				</Text>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66696d2e3f24780021f7ccff/images/360_F_657591708_1KC26Erazxm9sgNADjviZeAZHtrhlM7b.jpg?v=2024-06-13T09:31:00.743Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Powierz naprawę swojego sprzętu profesjonalistom. „Technical Repair Expert” to Twój niezawodny partner w świecie technologii.
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Zapewnimy Ci jakość, niezawodność i szybkość. Skontaktuj się z nami już dziś!
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Łączność
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="box2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="box3" />
			<Override slot="box" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});